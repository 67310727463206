import React, { useMemo } from "react";
import { useGlobalFilter, usePagination, useTable, useSortBy } from 'react-table';
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import { GlobalFilter } from "./GlobalFilter";

const Table = (props) => {

  const handleNextPageClick = () => {
    nextPage();
  }

  const handlePrevPageClick = () => {
    previousPage();
  }
  
  const companiesData = useMemo(() => [...props.companies], [props.companies]);
  const companiesColumns = useMemo(
    () =>
      props.companies[0]
      ? Object.keys(props.companies[0])
        .map(key => {
          if (key === 'id') {
            return { Header: key, accessor: key, Cell: e => e.value + 1 }
          }
          if (key === 'website') {
            return { Header: key, accessor: key, Cell: e => e.value === 'n/a' ? e.value : <a href={e.value} target="_blank">{e.value.split('/')[2].replace('www.', '')}</a> }
          }
          if (key === 'phone') {
            return { Header: key, accessor: key, Cell: e => e.value === 'n/a' ? e.value : <a href={`tel:${e.value}`}>{e.value}</a> }
          }
          if (key === 'seoAudit') {
            return { Header: key, accessor: key, Cell: e => e.value === 'n/a' ? e.value : <a href={e.value} target="_blank"><button className="seo-btn">Seo Audit</button></a> }
          }
          return { Header: key, accessor: key };
        })
        : [],
      [props.companies]
    );

  const tableInstance = useTable({
    columns: companiesColumns,
    data: companiesData,
    initialState: { pageSize: 20 }
  },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    allColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    prepareRow } = tableInstance;

    const { pageIndex, pageSize } = state;

  return (
    <>
    {props.companies.length > 1 && <GlobalFilter
      preGlobalFilteredRows={preGlobalFilteredRows}
      setGlobalFilter={setGlobalFilter}
      globalFilter={state.globalFilter}
      companiesData={companiesData}
      allColumns={allColumns}
    />}
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {column.isSorted ? (column.isSortedDesc ? <TiArrowSortedDown /> : <TiArrowSortedUp />) : <TiArrowUnsorted />}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, idx) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, idx) => (
                <td {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
        );
        })}
      </tbody>
    </table>
    {props.companies.length > 1 && <div className="pagination-controlls-container">
      <p className="page-count-text">Page <strong>{pageIndex + 1} of {pageOptions.length}</strong></p>
      <select value={pageSize} onChange={e => {setPageSize(Number(e.target.value))}}>
        {
          [20, 50, 100].map(pageSize => (
            <option key={pageSize} value={pageSize}>Show {pageSize}</option>
          ))
        }
      </select>
      <button onClick={handlePrevPageClick} disabled={!canPreviousPage}>previous</button>
      <button onClick={handleNextPageClick} disabled={!canNextPage}>next</button>
    </div>}
    </>
  );
}

export default Table;
