import React from "react";
import { Link } from 'react-router-dom';
import PeopleFinderImage from "../../img/people-finder.png";
import BusinessFinderImage from "../../img/business-finder.png";
import Datapoint from "../Datapoint";
import Footer from "../Footer";


const LandingPage = (props) => {

  const handleEmailChange = (e) => {
    props.setSignUpEmail(e.target.value);
  }

  // Infinite leads at your fingertips
  // Spend your time where it matters.

  // Time where it matters
  // Leads at your fingertips

  // Use our powerful lead finder to build lists of businesses in any industry, to add new revenue to your business with effective cold call and email marketing campaigns.

  return (
    <div className="landing-page">
      <h1 className="hero-text">Less time more leads. Your time where it matters.</h1>
      <p className="sub-hero-text">Use our lead finder to build lists of data in any industry, to generate more revenue with effective cold call and email marketing campaigns.</p>
      <div className="hero-cta-container">
        <input onChange={handleEmailChange} value={props.signUpEmail} type="text" placeholder="Enter Your Email" />
        <Link className='signup-link-container' to="/signup"><button className='signup-btn'>Create Free Account</button></Link>
      </div>
      <div className="people-finder-img-container">
        <img className="people-finder-img" src={BusinessFinderImage} />
      </div>
      <div className="find-leads-container">
        <div className="find-leads-text-container">
          <h6>Find top leads with our</h6>
          <h4>Top Lead Generation Software</h4>
          <p>Our top lead finder provides sales teams with top leads from anywhere in the world to help them open more doors and, ultimately, close more deals.</p>
        </div>
        <div className="datapoints-parent-container">
          <Datapoint number="200M" type="Phone numbers" />
          <Datapoint number="400M" type="Emails" />
          <Datapoint number="95%" type="Acuracy" />
        </div>
      </div>
      <div className="faq-container">
        {/* <h2>Frequently Asked Questions</h2> */}
      </div>
      <div className="footer-cta-container">
        <h2 className="footer-cta-text">Top Leads. Any Industry. <span>B2B Data. Interested?</span></h2>
        <Link className='signup-link-container' to="/signup"><button className='signup-btn'>Create Free Account</button></Link>
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
