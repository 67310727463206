import { useState, useEffect } from "react";
import BillingCard from '../BillingCard';
import PaymentModal from "../PaymentModal";
import Footer from "../Footer";

const Pricing = (props) => {
  const [isBillingMonthly, setIsBillingMonthly] = useState(true);
  const [isBillingYearly, setIsBillingYearly] = useState(false);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState('');

  useEffect(() => {
    isModalDisplayed && (document.body.style.overflow = 'hidden');
    !isModalDisplayed && (document.body.style.overflow = 'unset');
  }, [isModalDisplayed]);

  const closeButtonClick = () => {
    setIsModalDisplayed(false);
  };

  const handleMonthlyBillingClick = () => {
    setIsBillingMonthly(true);
    setIsBillingYearly(false);
  };

  const handleYearlyBillingClick = () => {
    setIsBillingMonthly(false);
    setIsBillingYearly(true);
  };

  const subscribeClick = (priceId) => {
    if (!props.user?.email) {
      props.paymentSignupRedirect();
    } else {
      setSelectedPriceId(priceId);
      setIsModalDisplayed(true);
    }
  };

  return (
    <>
      <div className="pricing-page">
        <h1 className="hero-text">Pricing</h1>
        <p className="sub-hero-text">Pick the plan that fits your needs. How many leads are right for you?</p>
        <div className="billing-options-container">
          <button onClick={handleMonthlyBillingClick} className={isBillingMonthly ? "monthly-billing-btn selected" : "monthly-billing-btn"}>Monthly Billing</button>
          <button onClick={handleYearlyBillingClick} className={isBillingYearly ? "yearly-billing-btn selected" : "yearly-billing-btn"}>Yearly Billing</button>
        </div>
        <div className="billing-cards-container">
          <BillingCard
            title="Trial"
            subtitle="Just to see what we're all about."
            price="Free"
            features={["5 trial searches", "Basic company data"]}
            monthlySubscriptionId=''
            yearlySubscriptionId=''
            subscribeClick={() => subscribeClick('')}
          />
          <BillingCard
            title="Professional"
            subtitle="Leads with reviews, google rankings and technology data."
            price="$19.99"
            features={["50 daily searches", "Business name", "Phone number", "Postal address", "Google ranking", "Website URL"]}
            monthlySubscriptionId='price_1LmjCtKjMO9e0lWdg9MaTQhh'
            yearlySubscriptionId='price_1LmjFPKjMO9e0lWdj1rCuvqC'
            subscribeClick={() => subscribeClick('price_1LmjCtKjMO9e0lWdg9MaTQhh')}
          />
          <BillingCard
            title="Enterprise"
            subtitle="For serious players, more than double the daily searches."
            price="$49.99"
            features={["120 daily searches", "Business name", "Phone number", "Postal address", "Google rating", "Website URL"]}
            monthlySubscriptionId='price_1LmjDDKjMO9e0lWduJb6AuHA'
            yearlySubscriptionId='price_1LmjGmKjMO9e0lWdkgwvLwKp'
            subscribeClick={() => subscribeClick('price_1LmjDDKjMO9e0lWduJb6AuHA')}
          />
        </div>
      </div>
      {isModalDisplayed && <PaymentModal closeButtonClick={closeButtonClick} priceId={selectedPriceId} />}
      <Footer />
    </>
  );
};

export default Pricing;
