import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import logo from '../img/topleadfinder-logo.png';

const Navbar = (props) => {

  return (
    <div className={props.user?.email ? 'navbar small' : 'navbar'}>
      <div className='landingpage-links-container'>
        <Link to="/">
          <img className='logo' src={logo} />
        </Link>
        {props.user?.email && <Link className='pricing-link-container' to="/business-finder">Business Finder</Link>}
        {props.user?.email && <Link className='pricing-link-container' to="/people-finder">People Finder</Link>}
        {props.user?.email && <Link className='pricing-link-container' to="/my-lists">My Lists</Link>}
        <Link className='pricing-link-container' to="/pricing">Pricing</Link>
      </div>
      <div className="account-options-container">
        {!props.user?.email && <Link className='login-link-container' to="/login"><button className='login-btn'>login</button></Link>}
        {!props.user?.email && <Link className='signup-link-container' to="/signup"><button className='signup-btn'>signup</button></Link>}
        {props.user?.email && <div className='credit-tracker'><span>{props.credits}</span> credits left</div>}
        {props.user?.email && <button className='logout-btn' onClick={props.logout}>logout</button>}
      </div>
    </div>
  );
}

export default Navbar;
