import React, { useState, useEffect } from "react";
import { useAsyncDebounce } from "react-table";
import { CSVLink } from "react-csv";
import { FaRegSave, FaFileCsv, FaCog } from "react-icons/fa";
import SettingsModal from "../SettingsModal";

export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  data = [], // Ensure data has a default value
  allColumns,
  type = 'data' // Ensure type has a default value
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  const [isModalDisplayed, setIsModalDisplayed] = useState(false);

  useEffect(() => {
    isModalDisplayed && (document.body.style.overflow = 'hidden');
    !isModalDisplayed && (document.body.style.overflow = 'unset');
  }, [isModalDisplayed]);

  const handleSettingsClick = () => {
    setIsModalDisplayed(true);
  }

  const closeButtonClick = () => {
    setIsModalDisplayed(false);
  }

  return (
    <>
      {isModalDisplayed && <SettingsModal
        allColumns={allColumns}
        closeButtonClick={closeButtonClick}
      />}
      <div className="table-options-container">
        <div className="settings-container">
          <button onClick={handleSettingsClick} className="settings-btn">
            <FaCog className="settings-icon" />
          </button>
        </div>
        <div className="search-container">
          <h2>Search:</h2>
          <input
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
          />
        </div>
        <div className="save-options-container">
          <CSVLink
            filename={`${type.toLowerCase()}-data.csv`}
            data={data}
            className="csv-download-btn"
          >
            <FaFileCsv className="download-icon" />CSV
          </CSVLink>
        </div>
      </div>
    </>
  );
}
