import React from "react";

const Datapoint = (props) => {
  return (
    <div className="datapoint-container">
      <h2>{props.number}</h2>
      <p>{props.type}</p>
  </div>
  );
}

export default Datapoint;
