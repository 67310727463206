import React from "react";
import { Link } from 'react-router-dom';
import { FaBriefcase } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';

const Home = () => {
  return (
    <div className="home-container">
      <h1>Home</h1>
      <p>Choose an app from the list below to get started.</p>
      <div className="options">
        <Link to="/business-finder" className="option-container">
          <FaBriefcase />
          <p>Business Finder</p>
        </Link>
        <Link to="/people-finder" className="option-container">
          <BsFillPersonFill />
          <p>People Finder</p>
        </Link>
      </div>
    </div>
  );
}

export default Home;
