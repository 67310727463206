import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import { GlobalFilter } from './GlobalFilter';

const Table = ({ data, updateCheckedState, updateNotes, updateTags }) => {
  // Define the custom sort function
  const tagSort = (rowA, rowB, columnId, desc) => {
    const aHasTags = rowA.original.tags && rowA.original.tags.length > 0;
    const bHasTags = rowB.original.tags && rowB.original.tags.length > 0;

    if (aHasTags === bHasTags) {
      return 0;
    }
    return aHasTags ? 1 : -1;
  };

  const columns = useMemo(() => {
    const commonColumns = [
      {
        Header: 'Checked',
        accessor: 'checked',
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={row.original.checked}
            onChange={() => {
              updateCheckedState(row.original.id, !row.original.checked);
            }}
          />
        ),
        sortType: (rowA, rowB) => {
          if (rowA.original.checked === rowB.original.checked) {
            return 0;
          }
          return rowA.original.checked ? 1 : -1;
        }
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({ row }) => (
          <textarea
            value={row.original.notes}
            onChange={(e) => {
              const newNotes = e.target.value;
              // Optimistically update the UI
              const updatedData = data.map(item =>
                item.id === row.original.id ? { ...item, notes: newNotes } : item
              );
              row.original.notes = newNotes;
              setTempData(updatedData);
            }}
            onBlur={(e) => {
              updateNotes(row.original.id, e.target.value);
            }}
          />
        )
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        Cell: ({ row }) => (
          <div>
            {(row.original.tags || []).map((tag, index) => (
              <span key={index} className="tag">
                {tag} <button onClick={() => handleRemoveTag(row.original.id, tag)}>x</button>
              </span>
            ))}
            <input
              type="text"
              placeholder="Add tag"
              onKeyDown={(e) => handleAddTag(e, row.original.id)}
            />
          </div>
        ),
        sortType: tagSort // Use custom sort function
      }
    ];

    let specificColumns = [];
    if (data.length > 0) {
      specificColumns = Object.keys(data[0]).map(key => ({
        Header: key,
        accessor: key,
        Cell: e => (key === 'email' || key === 'profileLink') ? <a href={e.value}>{e.value}</a> : e.value
      }));
    }

    return [...commonColumns, ...specificColumns.filter(col => col.accessor !== 'checked' && col.accessor !== 'notes' && col.accessor !== 'tags')];
  }, [data, updateCheckedState, updateNotes]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);

  const { pageIndex, pageSize } = state;

  const [tempData, setTempData] = useState(data);

  const handleAddTag = (e, id) => {
    if (e.key === 'Enter' && e.target.value) {
      const newTag = e.target.value;
      const updatedData = data.map(item =>
        item.id === id ? { ...item, tags: [...(item.tags || []), newTag] } : item
      );
      setTempData(updatedData);
      updateTags(id, updatedData.find(item => item.id === id).tags);
      e.target.value = '';
    }
  };

  const handleRemoveTag = (id, tag) => {
    const updatedData = data.map(item =>
      item.id === id ? { ...item, tags: (item.tags || []).filter(t => t !== tag) } : item
    );
    setTempData(updatedData);
    updateTags(id, updatedData.find(item => item.id === id).tags);
  };

  return (
    <>
      {data.length > 1 && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={state.globalFilter}
        />
      )}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.isSorted ? (column.isSortedDesc ? <TiArrowSortedDown /> : <TiArrowSortedUp />) : <TiArrowUnsorted />}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length > 1 && <div className="pagination-controlls-container">
        <p className="page-count-text">Page <strong>{pageIndex + 1} of {pageOptions.length}</strong></p>
        <select value={pageSize} onChange={e => { setPageSize(Number(e.target.value)) }}>
          {
            [20, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>Show {pageSize}</option>
            ))
          }
        </select>
        <button onClick={previousPage} disabled={!canPreviousPage}>previous</button>
        <button onClick={nextPage} disabled={!canNextPage}>next</button>
      </div>}
    </>
  );
};

export default Table;
