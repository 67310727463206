import React from "react";

const Searchbar = (props) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleFetchCompanies()
  }

  const handleJobTypeChange = (e) => {
    props.handleSetJobTitle(e.target.value);
  }

  const handleLocationChange = (e) => {
    props.handleSetLocation(e.target.value);
  }

  return (
    <form className="company-information-form">
      <input type="text" onChange={handleJobTypeChange} value={props.jobTitle} placeholder="Job Title:"/>
      <input type="text" onChange={handleLocationChange} value={props.location} placeholder="Location:"/>
      <button type="submit" onClick={handleSubmit}>Fetch Data</button>
    </form>
  );
}

export default Searchbar;
