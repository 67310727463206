import { useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  getAdditionalUserInfo
} from 'firebase/auth';
import { auth, provider, db } from './firebase-config.js';
import { collection, setDoc, getDoc, doc } from "firebase/firestore"; 
import './style.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Loading from './components/pages/Loading.js';
import Home from './components/pages/Home';
import Login from './components/pages/Login.js';
import SignUp from './components/pages/SignUp.js';
import ConfirmationPage from './components/pages/ConfirmationPage';
import MyLists from './components/pages/MyLists.js';
import ListViewer from './components/pages/ListViewer.js';
import LandingPage from './components/pages/LandingPage.js';
import BusinessFinder from './components/pages/BusinessFinder';
import PeopleFinder from './components/pages/PeopleFinder';
import Navbar from './components/Navbar';
import { useAuthState } from 'react-firebase-hooks/auth';
import Pricing from './components/pages/Pricing.js';

function App() {
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpPassword, setSignUpPassword] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [credits, setCredits] = useState(0);
  const [accountType, setAccountType] = useState('');

  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    console.log('Trigger credits use effect');
    if (user) {
      const docRef = doc(db, "users", `${user.uid}`);
      const docSnap = getDoc(docRef).then(snap => {
        console.log(snap.data());
        if (snap.data()) {
          const docRefSet = setDoc(doc(db, "users", user.uid), {
            ...snap.data(),
            currentCredits: credits
          }).then(console.log('Updated db'));
        }
      });
    }
  }, [credits]);

  console.log(credits);
  
  useEffect(() => {
    if (loading) {
      navigate('/loading');
      console.log('loading');
    } else {
      navigate("/");
    }
    if (user) navigate("/");
  }, [user, loading]);

  useEffect(() => {
    if (user) {
      console.log('Adding credits in react from db!!!');
      const docRef = doc(db, "users", `${user.uid}`);
      const docSnap = getDoc(docRef).then(snap => {
        if (!snap.exists()) throw new Error("not-found"); // document missing
        setCredits(snap.data().currentCredits);
        setAccountType(snap.data().accountType);
      });
    } else {
      setCredits(0);
      setAccountType('');
    }
  }, [user]);
  
  const signUp = async () => {
    try {
      const user = await createUserWithEmailAndPassword(auth, signUpEmail, signUpPassword);
      const userProfile = {
          uid: user.user.uid,
          email: user.user.email,
          name: user.user.displayName,
          provider: user.user.providerData[0].providerId,
          photoUrl: user.user.photoURL,
          accountType: 'Trial',
          currentCredits: 5,
          dailyCredits: 0
        }
      const docRef = await setDoc(doc(collection(db, "users"), user.user.uid), userProfile);
      navigate("/");
      console.log(user);
    } catch(err) {
      console.error(err.message);
    }
  }

  const loginWithEmail = async () => {
    try {
      const user = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      const { isNewUser } = getAdditionalUserInfo(user);
      if (isNewUser) {
        const userProfile = {
            uid: user.user.uid,
            email: user.user.email,
            name: user.user.displayName,
            provider: user.user.providerData[0].providerId,
            photoUrl: user.user.photoURL,
            accountType: 'Trial',
            currentCredits: 5,
            dailyCredits: 0
          }
        const docRef = await setDoc(doc(collection(db, "users"), user.user.uid), userProfile);
      } else {
        console.log("User already exists");
      }
      navigate("/");
      console.log(user);
    } catch(err) {
      console.error(err.message);
    }
  }

  const loginWithGoogle = async () => {
    try {
      const user = await signInWithPopup(auth, provider);
      const { isNewUser } = getAdditionalUserInfo(user);
        if (isNewUser) {
          const userProfile = {
              uid: user.user.uid,
              email: user.user.email,
              name: user.user.displayName,
              provider: user.user.providerData[0].providerId,
              photoUrl: user.user.photoURL,
              accountType: 'Trial',
              currentCredits: 5,
              dailyCredits: 0
            }
          const docRef = await setDoc(doc(collection(db, "users"), user.user.uid), userProfile);
        } else {
          console.log("User already exists");
        }
      navigate("/");
      console.log(user);
    } catch(err) {
      console.error(err.message);
    }
  }

  const paymentSignupRedirect = () => {
    navigate("/signup");
  }

  const logout = async () => {
    await signOut(auth);
    navigate("/");
  }

  return (
    <div className={user?.email ? "App signedin" : "App"}>
      {!loading && <Navbar
        user={user}
        logout={logout}
        credits={credits}
      />}
      <Routes>
        <Route exact path='/' element={!user?.email ? <LandingPage user={user} setSignUpEmail={setSignUpEmail} /> : <Home user={user} />} />
        <Route exact path="/login" element={
          <Login
            loginEmail={loginEmail}
            setLoginEmail={setLoginEmail}
            loginPassword={loginPassword}
            setLoginPassword={setLoginPassword}
            loginWithEmail={loginWithEmail}
            loginWithGoogle={loginWithGoogle}
          />} />
        <Route exact path="/signup" element={
          <SignUp
            signUpEmail={signUpEmail}
            setSignUpEmail={setSignUpEmail}
            signUpPassword={signUpPassword}
            setSignUpPassword={setSignUpPassword}
            loginWithGoogle={loginWithGoogle}
            signUp={signUp}
          />} />
        <Route exact path='/business-finder' element={<BusinessFinder
          credits={credits}
          setCredits={setCredits}
          accountType={accountType}
        />} />
        <Route exact path='/people-finder' element={<PeopleFinder
          credits={credits}
          setCredits={setCredits}
          accountType={accountType}
        />} />
        <Route exact path='/loading' element={<Loading />} />
        <Route exact path='/pricing' element={<Pricing user={user} paymentSignupRedirect={paymentSignupRedirect} />} />
        <Route exact path="/confirmation" element={<ConfirmationPage />} />
        <Route path="/my-lists" element={<MyLists />} />
        <Route exact path='/list-viewer/:id' element={<ListViewer />} />
      </Routes>
    </div>
  );
}

export default App;
