import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '../people-finder-components/Table';
import Searchbar from '../people-finder-components/Searchbar';
import loading from '../../img/loading.gif';
import OutOfCreditsModal from '../OutOfCreditsModal';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase-config';

const PeopleFinder = (props) => {
  const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [location, setLocation] = useState('');
  const [people, setPeople] = useState([]);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);

  useEffect(() => {
    isModalDisplayed && (document.body.style.overflow = 'hidden');
    !isModalDisplayed && (document.body.style.overflow = 'unset');
  }, [isModalDisplayed]);

  useEffect(() => {
    const storedPeople = JSON.parse(localStorage.getItem('people'));
    if (storedPeople) {
      setPeople(storedPeople);
    }
  }, []);

  const closeButtonClick = () => {
    setIsModalDisplayed(false);
  };

  const fetchPeople = async (jobTitle, location) => {
    if (!user) return;

    const response = await axios.get(`http://localhost:4242/v1/linkedin-scraper/${jobTitle}/${location}`, {
      params: { userId: user.uid }
    }).catch(err => console.error(err));

    if (response) {
      console.log('Got Data!!!');
      setPeople(response.data);
      localStorage.setItem('people', JSON.stringify(response.data));
      props.setCredits(prevCredits => (prevCredits - 1));
    }
    setIsLoading(false);
    setJobTitle('');
    setLocation('');
  };

  const handleFetchPeople = () => {
    if (props.credits <= 0) {
      setIsModalDisplayed(true);
      return;
    }
    if (jobTitle.length > 1 && location.length > 1) {
      setIsLoading(true);
      fetchPeople(jobTitle, location);
    }
  };

  const handleSetJobTitle = (value) => {
    setJobTitle(value);
  };

  const handleSetLocation = (value) => {
    setLocation(value);
  };

  return (
    <>
      <div className='people-finder'>
        <h1 className='app-title'>People Finder</h1>
        <Searchbar
          handleFetchPeople={handleFetchPeople}
          handleSetJobTitle={handleSetJobTitle}
          handleSetLocation={handleSetLocation}
          jobTitle={jobTitle}
          location={location}
        />
        {isLoading && <img className='loading-gif' src={loading} />}
        {!isLoading && <Table
          people={people}
          jobTitle={jobTitle}
          location={location}
        />}
      </div>
      {isModalDisplayed && <OutOfCreditsModal closeButtonClick={closeButtonClick} accountType={props.accountType} />}
    </>
  );
};

export default PeopleFinder;
