import React, { useEffect, useState } from 'react';
import { db } from '../../firebase-config.js';
import { collection, getDocs, deleteDoc, doc, updateDoc, addDoc } from "firebase/firestore"; 
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase-config.js';
import { FaBriefcase, FaFileCsv } from 'react-icons/fa'; // Import FaFileCsv
import { BsFillPersonFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import DeleteModal from "../DeleteModal";
import Papa from 'papaparse';

const MyLists = () => {
  const [user] = useAuthState(auth);
  const [searches, setSearches] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [editingName, setEditingName] = useState({}); // Track the editing names

  useEffect(() => {
    if (user) {
      const fetchSearches = async () => {
        const businessSearchesSnapshot = await getDocs(collection(db, "searches", user.uid, "businessSearches"));
        const peopleSearchesSnapshot = await getDocs(collection(db, "searches", user.uid, "peopleSearches"));
        const csvSearchesSnapshot = await getDocs(collection(db, "searches", user.uid, "csvSearches"));

        const formatDate = (date) => {
          const options = { month: 'short', day: 'numeric' };
          return date.toLocaleDateString(undefined, options);
        };

        const mapData = (snapshot, type) => snapshot.docs.map(doc => {
          const data = doc.data();
          const createdAt = data.createdAt.toDate();
          const defaultName = `${formatDate(createdAt)} - ${type}`;
          return {
            id: doc.id,
            ...data,
            createdAt,
            type,
            name: data.name || defaultName,
            userId: user.uid // Add userId to the search object
          };
        });

        const businessSearchesData = mapData(businessSearchesSnapshot, 'Business');
        const peopleSearchesData = mapData(peopleSearchesSnapshot, 'People');
        const csvSearchesData = mapData(csvSearchesSnapshot, 'CSV');

        const combinedSearches = [...businessSearchesData, ...peopleSearchesData, ...csvSearchesData];
        combinedSearches.sort((a, b) => b.createdAt - a.createdAt);

        setSearches(combinedSearches);

        // Save to local storage
        localStorage.setItem('searches', JSON.stringify(combinedSearches));
      };

      fetchSearches();
    } else {
      // Retrieve from local storage
      const storedSearches = JSON.parse(localStorage.getItem('searches')) || [];
      setSearches(storedSearches.map(search => ({
        ...search,
        createdAt: new Date(search.createdAt)
      })));
    }
  }, [user]);

  const handleDelete = async (id, type) => {
    try {
      await deleteDoc(doc(db, "searches", user.uid, type === 'Business' ? "businessSearches" : type === 'People' ? "peopleSearches" : "csvSearches", id));
      const updatedSearches = searches.filter(search => search.id !== id);
      setSearches(updatedSearches);
      // Update local storage
      localStorage.setItem('searches', JSON.stringify(updatedSearches));
      setShowModal(false);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const confirmDelete = (id, type) => {
    setDeleteInfo({ id, type });
    setShowModal(true);
  };

  const handleNameChange = (id, newName) => {
    setEditingName(prev => ({ ...prev, [id]: newName }));
  };

  const handleNameBlur = async (id, type) => {
    const newName = editingName[id] !== undefined ? editingName[id] : '';
    try {
      if (!user) {
        console.error("User not authenticated");
        return;
      }
      const searchRef = doc(db, "searches", user.uid, type === 'Business' ? "businessSearches" : type === 'People' ? "peopleSearches" : "csvSearches", id);
      console.log('Updating document:', searchRef.path, { name: newName });
      await updateDoc(searchRef, { name: newName });
      const updatedSearches = searches.map(search => 
        search.id === id ? { ...search, name: newName } : search
      );
      setSearches(updatedSearches);
      // Update local storage
      localStorage.setItem('searches', JSON.stringify(updatedSearches));
      setEditingName(prev => ({ ...prev, [id]: undefined })); // Clear the temporary state
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const data = results.data;
        const newSearch = {
          name: file.name,
          createdAt: new Date(),
          type: 'CSV',
          results: data,
          userId: user.uid // Add userId to the new search object
        };

        try {
          if (!user) {
            console.error("User not authenticated");
            return;
          }
          const docRef = await addDoc(collection(db, "searches", user.uid, "csvSearches"), newSearch);
          setSearches(prevSearches => [...prevSearches, { id: docRef.id, ...newSearch }]);
          localStorage.setItem('searches', JSON.stringify([...searches, { id: docRef.id, ...newSearch }]));
        } catch (error) {
          console.error("Error uploading CSV: ", error);
        }
      },
      error: (error) => {
        console.error("Error parsing CSV: ", error);
      }
    });
  };

  return (
    <div className="my-lists-container">
      <h1>My Lists</h1>
      <div className="options">
        <input type="file" accept=".csv" onChange={handleFileUpload} />
        {searches.length > 0 ? (
          <ul>
            {searches.map((search, index) => (
              <li key={index} className="option-container">
                <div className="search-details">
                  {search.type === 'Business' ? <FaBriefcase /> : search.type === 'People' ? <BsFillPersonFill /> : <FaFileCsv />}
                  <p><strong>Type:</strong> {search.type}</p>
                  <p><strong>Date:</strong> {search.createdAt.toString()}</p>
                  <input 
                    type="text"
                    value={editingName[search.id] !== undefined ? editingName[search.id] : search.name}
                    onChange={(e) => handleNameChange(search.id, e.target.value)}
                    onBlur={() => handleNameBlur(search.id, search.type)}
                  />
                  <Link to={`/list-viewer/${search.id}`} state={{ search }}>
                    View List
                  </Link>
                </div>
                <button onClick={() => confirmDelete(search.id, search.type)}>Delete</button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No searches found.</p>
        )}
      </div>
      <DeleteModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={() => handleDelete(deleteInfo.id, deleteInfo.type)}
      />
    </div>
  );
};

export default MyLists;
