import React from 'react';

const ConfirmationPage = () => {
  return (
    <div className="confirmation-page">
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase. Your subscription has been activated.</p>
    </div>
  );
};

export default ConfirmationPage;
