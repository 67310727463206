import React from 'react';
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const OutOfCreditsModal = (props) => {

const navigate = useNavigate();

  const handleCloseClick = () => {
    props.closeButtonClick();
  }

  const handleNormalClick = (e) => {
    e.stopPropagation();
  }

  const handleUpgradeClick = () => {
    navigate('/pricing');
  }

  return (
    <div onClick={handleCloseClick} className="settings-modal-parent">
      <div onClick={handleNormalClick} className="settings-modal">
        <div onClick={handleCloseClick} className="close-btn">
          <IoClose />
        </div>
        <h1>Out of credits</h1>
        {props.accountType === 'Trial' ? <p>Your trial has ended! Please upgrade your plan to continue.</p> : <p>You are out of credits! Please wait until tomorrow or upgrade your plan.</p>}
        <button onClick={handleUpgradeClick}>Upgrade</button>
        <a onClick={handleCloseClick}>close</a>
      </div>
    </div>
  );
}

export default OutOfCreditsModal;
