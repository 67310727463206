import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p>© 2022 Top Lead Finder LTD. All rights reserved.</p>
    </div>
  );
}

export default Footer;
