import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '../saved-lists-components/Table';
import { db } from '../../firebase-config';
import { doc, updateDoc } from 'firebase/firestore';

const ListViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [search, setSearch] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (!location.state || !location.state.search) {
      navigate('/my-lists');
    } else {
      const { search } = location.state;
      setSearch(search);
      setResults(search.results);
    }
  }, [location.state, navigate]);

  const updateCheckedState = async (id, checked) => {
    const updatedResults = results.map(item => item.id === id ? { ...item, checked } : item);
    setResults(updatedResults);

    if (!search || !search.userId) {
      console.error('Search data or user ID is not available');
      return;
    }

    const searchRef = doc(db, `searches/${search.userId}/${search.type.toLowerCase()}Searches/${search.id}`);
    console.log('Updating document:', searchRef.path, { results: updatedResults });

    try {
      await updateDoc(searchRef, { results: updatedResults });
    } catch (error) {
      console.error('Error updating document: ', error);
      setResults(results);
    }
  };

  const updateNotes = async (id, notes) => {
    const updatedResults = results.map(item => item.id === id ? { ...item, notes } : item);
    setResults(updatedResults);

    if (!search || !search.userId) {
      console.error('Search data or user ID is not available');
      return;
    }

    const searchRef = doc(db, `searches/${search.userId}/${search.type.toLowerCase()}Searches/${search.id}`);
    console.log('Updating document:', searchRef.path, { results: updatedResults });

    try {
      await updateDoc(searchRef, { results: updatedResults });
    } catch (error) {
      console.error('Error updating document: ', error);
      setResults(results);
    }
  };

  const updateTags = async (id, tags) => {
    const updatedResults = results.map(item => item.id === id ? { ...item, tags } : item);
    setResults(updatedResults);

    if (!search || !search.userId) {
      console.error('Search data or user ID is not available');
      return;
    }

    const searchRef = doc(db, `searches/${search.userId}/${search.type.toLowerCase()}Searches/${search.id}`);
    console.log('Updating document:', searchRef.path, { results: updatedResults });

    try {
      await updateDoc(searchRef, { results: updatedResults });
    } catch (error) {
      console.error('Error updating document: ', error);
      setResults(results);
    }
  };

  if (!search) {
    return null;
  }

  return (
    <div className='table-container'>
      <Table 
        data={results}
        updateCheckedState={updateCheckedState}
        updateNotes={updateNotes}
        updateTags={updateTags}
      />
    </div>
  );
};

export default ListViewer;
