// BillingCard.js
import React from "react";
import { FcCheckmark } from 'react-icons/fc';

const BillingCard = (props) => {
  const features = props.features.map((feature, index) => (
    <div className="feature" key={index}>
      <FcCheckmark /><p>{feature}</p>
    </div>
  ));

  const handleSubscribeClick = () => {
    props.subscribeClick();
  };

  return (
    <div className={`billing-card ${props.title.toLowerCase()}`}>
      <div className="card-header">
        <h4>{props.title}</h4>
        <p>{props.subtitle}</p>
        <h2>{props.price}<span>/mo</span></h2>
        <button onClick={handleSubscribeClick}>Get Started</button>
      </div>
      <div className="card-description">
        <h6>WHAT'S INCLUDED</h6>
        {features}
      </div>
    </div>
  );
};

export default BillingCard;
