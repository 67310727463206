import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '../business-finder-components/Table';
import Searchbar from '../business-finder-components/Searchbar';
import loading from '../../img/loading.gif';
import OutOfCreditsModal from '../OutOfCreditsModal';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase-config';

const BusinessFinder = (props) => {
  const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [location, setLocation] = useState('');
  const [companies, setCompanies] = useState([]);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);

  useEffect(() => {
    isModalDisplayed && (document.body.style.overflow = 'hidden');
    !isModalDisplayed && (document.body.style.overflow = 'unset');
  }, [isModalDisplayed]);

  useEffect(() => {
    const storedCompanies = JSON.parse(localStorage.getItem('companies'));
    if (storedCompanies) {
      setCompanies(storedCompanies);
    }
  }, []);

  const closeButtonClick = () => {
    setIsModalDisplayed(false);
  }

  const fetchCompanies = async (businessType, location) => {
    if (!user) return;

    const response = await axios.get(`http://localhost:4242/v1/b2b-lead-gen/${businessType}/${location}`, {
      params: { userId: user.uid }
    }).catch(err => console.error(err));

    if (response) {
      console.log('Got Data!!!');
      setCompanies(response.data);
      localStorage.setItem('companies', JSON.stringify(response.data));
      props.setCredits(prevCredits => (prevCredits - 1));
    }
    setIsLoading(false);
    setJobTitle('');
    setLocation('');
  }

  const handleFetchCompanies = () => {
    if (props.credits <= 0) {
      setIsModalDisplayed(true);
      return;
    }
    if (jobTitle.length > 1 && jobTitle.length > 1) {
      setIsLoading(true);
      fetchCompanies(jobTitle, location);
    }
  }

  const handleSetJobTitle = (value) => {
    setJobTitle(value);
  }

  const handleSetLocation = (value) => {
    setLocation(value);
  }

  return (
    <>
      <div className='business-finder'>
        <h1 className='app-title'>Business Finder</h1>
        <Searchbar
          handleFetchCompanies={handleFetchCompanies}
          handleSetJobTitle={handleSetJobTitle}
          handleSetLocation={handleSetLocation}
          jobTitle={jobTitle}
          location={location}
        />
        {isLoading && <img className='loading-gif' src={loading} />}
        {!isLoading && <Table
          companies={companies}
          jobTitle={jobTitle}
          location={location}
        />}
      </div>
      {isModalDisplayed && <OutOfCreditsModal closeButtonClick={closeButtonClick} accountType={props.accountType} />}
    </>
  );
}

export default BusinessFinder;
