import React from "react";
import { IoClose } from 'react-icons/io5';

const SettingsModal = (props) => {

  const handleCloseClick = () => {
    props.closeButtonClick();
  }

  const handleNormalClick = (e) => {
    e.stopPropagation();
  }

  return (
    <div onClick={handleCloseClick} className="settings-modal-parent">
      <div onClick={handleNormalClick} className="settings-modal">
        <div onClick={handleCloseClick} className="close-btn">
          <IoClose />
        </div>
        <h1>Settings</h1>
        <div className="column-checkboxes-settings-container">
          <h4>Display Columns</h4>
          {props.allColumns.map(column => (
              <div className="column-checkbox-container" key={column.id}>
                <label>
                  {column.Header}
                  <input type="checkbox" {...column.getToggleHiddenProps()} />
                </label>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default SettingsModal;
