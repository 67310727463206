import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({ closeButtonClick, priceId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (paymentMethodError) {
      setError(paymentMethodError.message);
      setLoading(false);
      return;
    }

    const response = await fetch('http://localhost:4242/create-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: 'Customer Name', // Replace with actual customer name
        email: 'customer@example.com', // Replace with actual customer email
        paymentMethodId: paymentMethod.id,
        priceId: priceId,
      }),
    });

    if (!response.ok) {
      setError('Failed to create subscription');
      setLoading(false);
      return;
    }

    const data = await response.json();
    const clientSecret = data.clientSecret;

    // Log clientSecret for debugging
    console.log('Client Secret:', clientSecret);

    const result = await stripe.confirmCardPayment(clientSecret);

    // Log result for debugging
    console.log('Result:', result);

    if (result.error) {
      setError(result.error.message);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded');
        navigate('/confirmation');
      } else {
        setError(`Payment failed with status: ${result.paymentIntent.status}`);
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe || loading}>
        {loading ? 'Processing...' : 'Pay'}
      </button>
      {error && <div>{error}</div>}
    </form>
  );
};

const PaymentModal = ({ closeButtonClick, priceId }) => {
  return (
    <div onClick={closeButtonClick} className="settings-modal-parent">
      <div onClick={(e) => e.stopPropagation()} className="settings-modal">
        <div onClick={closeButtonClick} className="close-btn">
          <IoClose />
        </div>
        <h1>Payment</h1>
        <Elements stripe={stripePromise}>
          <CheckoutForm closeButtonClick={closeButtonClick} priceId={priceId} />
        </Elements>
      </div>
    </div>
  );
};

export default PaymentModal;
